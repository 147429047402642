<template>
  <div>
    <FullContactVue />
  </div>
</template>

<script>
  import FullContactVue from '../components/sections/FullContact.vue'

  export default {
    components: {
      FullContactVue
    }
  }
</script>