<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="copyright mb-0 text-center">Feito usando VUE.js por: <a class="text-white fw-bolder" href="https://github.com/willmustafa" target="_blank">Willian Mustafa</a> --- Acesse o meu Github!
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--bs-gray-700);
    color: white;
    z-index: 999999;
    padding: 0.5rem;
}
</style>