<template>
  <div class="bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-12">
          <div class="py-5">
            <h1 class="display-2 mt-3 fw-bold">About Us</h1>
            <span class="divider-line mt-4"></span>
            <p class="mt-3">Metropolis Studios is Europe's #1 independent recording studio and home to the best
              mastering engineers
              in the world.</p>
            <p>Our facilities include an iconic range of dynamic tracking and mixing studios, the best mastering suites
              found anywhere in the world, a series of new writing and production rooms available for short and longterm
              lease, and other serviced spaces for rent currently occupied by the likes of Roland and Spotify.</p>
            <button class="btn btn-outline-primary"><router-link to="/about">Learn more</router-link></button>
          </div>
        </div>
        <div class="col-md-4 col-12 offset-md-1 pe-0 d-flex">
          <img class="img-fluid"
            src="https://images.unsplash.com/photo-1574517947730-55cb23e608c2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>

</style>